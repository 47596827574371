import './novedades.css';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import React, { useEffect, useState } from 'react';

// Import the pre-fetched JSON data
import noticiasData from '../../data/noticias.json';
import testimoniosData from '../../data/testimonios.json';

function NovItem() {
  const { itemId } = useParams(); 
  const location = useLocation();
  const [subGalleryHeight, setSubGalleryHeight] = useState('auto');
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fullImageSrc, setFullImageSrc] = useState(null);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Go back one step in the history stack
  };

  const openFullImage = (src) => {
    setFullImageSrc(src);
  };

  const closeFullImage = () => {
    setFullImageSrc(null);
  };

  const loadImage = (images, img) => {
    img.addEventListener('load', () => {
      const allLoaded = [...images].every(image => image.complete);
      if (allLoaded) {
        setImagesLoaded(true);
      }
    });
  };

  const handleImageLoad = () => {
    const images = document.querySelectorAll('.subgallery img');
    const allLoaded = Array.from(images).every(img => img.complete);

    if (allLoaded) {
      updateGalleryHeight();
    }
  };

  const updateGalleryHeight = () => {
    const cnewsidDiv = document.querySelector('.cnewsid');
    const n1Div = document.querySelector('.n1');
    const csubgalDiv = document.querySelector('.subgallery');
  
    if (cnewsidDiv && n1Div) {
      const cnewsidHeight = cnewsidDiv.clientHeight;
      const n1Height = n1Div.clientHeight;
      const csubgalHeight = csubgalDiv.clientHeight;

      const remainingHeight = cnewsidHeight - (n1Height - csubgalHeight);

      setSubGalleryHeight(remainingHeight > 0 ? remainingHeight + 'px' : '0');
    }
  };

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);

    const loadItemData = () => {
      setIsLoading(true);
      try {
        const data = location.pathname.includes('/noticias')
          ? noticiasData
          : testimoniosData;

        const selectedItem = data.find(item => item.id.toString() === itemId);

        if (selectedItem) {
          setItemData(selectedItem);
        } else {
          console.error('Item not found');
        }
      } catch (error) {
        console.error('Error loading item data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadItemData();

    const images = document.querySelectorAll('.news img');
    const allImagesLoaded = [...images].every(img => img.complete);
    if (allImagesLoaded) {
      setImagesLoaded(true);
    } else {
      images.forEach(img => loadImage(images, img));
    }

    window.addEventListener('resize', updateGalleryHeight);

    return () => {
      window.removeEventListener('resize', updateGalleryHeight);
      images.forEach(img => img.removeEventListener('load', loadImage));
    };
  }, [location.pathname, itemId]);

  useEffect(() => {
    if (imagesLoaded) {
      updateGalleryHeight();
    }
  }, [imagesLoaded]);

  if (isLoading) {
    return (
      <div className="loading-placeholder">
        <p>Procesando...</p>
      </div>
    );
  }

  if (!itemData) {
    return (
      <div className="loading-placeholder">
        <p>Procesando... Intente más tarde</p>
      </div>
    );
  }

  const imageUrl = itemData.image ? itemData.image : 'https://redmaestra.blob.core.windows.net/static/media/default.png';

  return (
    <>
      <div className="news">
        <div className="card cnewsid">
          <h1>{itemData.title}</h1>
          <p className="card-title badge rounded-pill text-bg-secondary cti ctitem ctilink">
            {location.pathname.includes('/testimonios') ? itemData.date.split('-')[0] : itemData.date}
          </p>
          <img src={imageUrl} alt={itemData.title} className="n2" />
          <ReactMarkdown>{itemData.body}</ReactMarkdown>
          {itemData.link && (
            <a className="badge rounded-pill text-bg-primary cti ctitem ctilink" href={itemData.link} target="_blank" rel="noopener noreferrer">
              Ingresa Aquí
            </a>
          )}
        </div>
        <div className="n1">
          <img src={imageUrl} alt={itemData.title} className="main_gal" />

          <div className="subgallery" style={{ height: subGalleryHeight }}>
            {itemData.grupo_photos && itemData.grupo_photos.map((photo, index) => (
              <img
                key={index}
                src={`${photo.image}?quality=low`}
                alt={`Grupo ${index + 1}`}
                className="grupo-photo"
                onLoad={handleImageLoad}
                onClick={() => openFullImage(photo.image)}
              />
            ))}
          </div>
        </div>
      </div>

      {fullImageSrc && (
        <div className="full-image-modal" onClick={closeFullImage}>
          <img src={fullImageSrc} alt="Full Quality" />
        </div>
      )}

      {itemData.grupo_photos && itemData.grupo_photos.length > 0 && (
        <div className="subgallery n3">
          {itemData.grupo_photos.map((photo, index) => (
            <img
              key={index}
              src={`${photo.image}?quality=low`}
              alt={`Grupo ${index + 1}`}
              className="grupo-photo"
              onLoad={handleImageLoad}
              onClick={() => openFullImage(photo.image)}
            />
          ))}
        </div>
      )}

      {itemData.video && (
        <video className="spec_vid" controls>
          <source src={itemData.video} type="video/mp4" />
        </video>
      )}
      <div className="volver">
        <button onClick={goBack} className="btn btn-primary bl">
          Volver
        </button>
      </div>
    </>
  );
}

export default NovItem;
