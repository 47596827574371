import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './donationPopup.css'; // Make sure to create this CSS file

// Directly import the image
import donationImage from '../../static/popup/2024 Lipigas Artefactos a Gas.png';

const DonationPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const showPopup = !localStorage.getItem('donationPopupShown');
    if (showPopup) {
      setIsVisible(true);
      localStorage.setItem('donationPopupShown', 'true');
    }
  }, []);

  const handleOutsideClick = () => {
    setIsVisible(false);
  };

  const handleImageClick = (e) => {
    e.stopPropagation(); // Prevent the click from being detected by the overlay
    navigate('/convocatorias');
  };

  if (!isVisible) return null;

  return (
    <div className="donation-popup-overlay" onClick={handleOutsideClick}>
      <div className="donation-popup-content" onClick={e => e.stopPropagation()}>
        <img
          src={donationImage}
          alt="Donate to Chile Fire Victims"
          onClick={handleImageClick}
          className="donation-popup-image"
        />
      </div>
    </div>
  );
};

export default DonationPopup;
