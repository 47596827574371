import React from 'react';
import Novedad from '../components/4. Novedades/novedades';

const componentMapping = {
  novedades: Novedad,
};

const Novedades = () => {
  return (
    <>
    {Object.entries(componentMapping).map(([id, Component]) => (
        <div key={id} id={id}>
          <Component />
        </div>
      ))}
    </>
  );
};

export default Novedades;
