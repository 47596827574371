const isProduction = process.env.REACT_APP_IS_PRODUCTION === 'true';
const productionBaseUrl = process.env.REACT_APP_PRODUCTION_IMAGE_BASE_URL;

const getMediaUrl = (mediaPath) => {
  if (isProduction) {
    return `${productionBaseUrl}${mediaPath}`;
  } else {
    try {
      return require(`../${mediaPath}`);
    } catch (error) {
      console.warn(`Media file not found: ${mediaPath}`);
      return null; // Or return a fallback URL/path
    }
  }
};

export default getMediaUrl;

