import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './novedades.css';
import noticiasData from '../../data/noticias.json';
import testimoniosData from '../../data/testimonios.json';

function Novedades() {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(8);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = () => {
      setIsLoading(true);
      try {
        const rawData = location.pathname.includes('/noticias')
          ? noticiasData
          : testimoniosData;
          
        // Sort the data from newest to oldest
        const sortedData = rawData.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
        
        setData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [location]);

  useEffect(() => {
    const normalizeText = (text) =>
      text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

    const filterData = () => {
      let filtered = data;

      if (searchTerm) {
        const normalizedSearchTerm = normalizeText(searchTerm);
        filtered = filtered.filter(item =>
          normalizeText(item.title).includes(normalizedSearchTerm) ||
          (item.body && normalizeText(item.body).includes(normalizedSearchTerm))
        );
      }

      if (selectedYear) {
        filtered = filtered.filter(item => extractYearFromDate(item.date) === selectedYear);
      }

      setFilteredData(filtered);
    };

    filterData();
  }, [data, searchTerm, selectedYear]);

  const extractYearFromDate = (date) => date.split('-')[0];

  const handleYearSelection = (event) => {
    setSelectedYear(event.target.value);
  };

  const showAllData = () => {
    setSearchTerm('');
    setSelectedYear('');
    setFilteredData(data); // Reset to the original sorted data
    setVisibleCount(8);
  };

  const getImageUrl = (item) => {
    return item.image ? item.image : 'https://redmaestra.blob.core.windows.net/media/default.png';
  };

  const loadMore = () => {
    setVisibleCount(prevCount => prevCount + 8);
  };

  const years = location.pathname.includes('/noticias') ? ["2023", "2022", "2021", "2020", "2019"] : ["2023", "2022", "2021", "2020"];

  if (isLoading) {
    return (
      <div className="loading-placeholder">
        <p>Procesando...</p>
      </div>
    );
  }

  if (!data.length) {
    return (
      <div className="loading-placeholder">
        <p>Procesando... Intente más tarde</p>
      </div>
    );
  }

  return (
    <>
      <div className="filters">
        <input 
          type="text" 
          className="search-bar" 
          placeholder="Buscar..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="year-selector">
          <select 
            className="year-dropdown" 
            value={selectedYear} 
            onChange={handleYearSelection}
          >
            <option value="">Todas</option>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <button className="btn btn-primary reset-button" onClick={showAllData}>
          Resetear
        </button>
      </div>

      {filteredData.slice(0, visibleCount).map(item => (
        <div key={item.id} className="card cnews">
          <img src={getImageUrl(item)} alt={item.title} className={`card-img-top cit ${location.pathname.includes('/testimonios') ? 't-img' : ''}`}/>
          <div className="card-body cb">
            <h5 className="card-title badge rounded-pill text-bg-secondary cti">{location.pathname.includes('/testimonios') ? item.date.split('-')[0] : item.date}</h5>
            <p className="card-text cte">{item.title}</p>
            <Link to={`/${location.pathname.includes('/noticias') ? 'noticias' : 'testimonios'}/${item.id}`} className="btn btn-primary bl">Leer más</Link>
          </div>
        </div>
      ))}
      {visibleCount < filteredData.length && (
        <button onClick={loadMore} className="btn btn-primary bl lm">Mostrar más</button>
      )}
    </>
  );
}

export default Novedades;
