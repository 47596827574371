import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import HomePage from './pages/HomePage';
import Nosotras from './pages/Nosotras'; 
import Programas from './pages/Programas'; 
import Convocatorias from './pages/Convocatorias';
import Novedades from './pages/Novedades';
import NovItem from './components/4. Novedades/novItem';
import LinkTree from './pages/LinkTree';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Wrap each route that requires the Layout with the Layout component */}
        <Route path="/" element={<Layout><HomePage /></Layout>} />
        <Route path="/nosotras" element={<Layout><Nosotras /></Layout>} />
        <Route path="/programas" element={<Layout><Programas /></Layout>} />
        <Route path="/convocatorias" element={<Layout><Convocatorias /></Layout>} />
        <Route path="/noticias" element={<Layout><Novedades /></Layout>} />
        <Route path="/noticias/:itemId" element={<Layout><NovItem /></Layout>} />
        <Route path="/testimonios" element={<Layout><Novedades /></Layout>} />
        <Route path="/testimonios/:itemId" element={<Layout><NovItem /></Layout>} />

        {/* Route without Layout */}
        <Route path="/linktree" element={<LinkTree />} />

        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
