import React from 'react';
import './conv_pred.css';

function ConvPred() {

    return (
        <>
              <div className="card cconvid">
                <img src='https://redmaestra.blob.core.windows.net/static/images/comunas/Santiago.png' alt="convgif"/>
                <h2>Mantención y Reparación Artefactos a Gas</h2>
                <h5>Santiago</h5>
                <span>Requisitos:</span>
                <p>Mujeres mayores de 18 años con conocimientos en gasfitería e instalaciones sanitarias.</p>
                <a href="https://redmaestra.azurewebsites.net/api/seleccion/agregar_postulante_proceso/6/" className="btn btn-primary bl">Postular</a>
              </div>
              <div className="card cconvid">
                <img src='https://redmaestra.blob.core.windows.net/static/images/comunas/Concepción.png' alt="convgif"/>
                <h2>Mantención y Reparación Artefactos a Gas</h2>
                <h5>Concepción</h5>
                <span>Requisitos:</span>
                <p>Mujeres mayores de 18 años con conocimientos en gasfitería e instalaciones sanitarias.</p>
                <a href="https://redmaestra.azurewebsites.net/api/seleccion/agregar_postulante_proceso/7/" className="btn btn-primary bl">Postular</a>
              </div>
        </>
    );
};

export default ConvPred;

